import { useState, useEffect } from "react";
import { init as initCornerstone } from "../../utils/cornerstone";
import { Loader } from "../../components/Ui/Loader";
import { ErrorComponent } from "../../components/Ui/Error";
import { SuccessComponent } from "../../components/Ui/Success";
import { DicomViewer } from "../../components/DicomViewer/DicomViewer";

import { useGoogleLogin } from '@react-oauth/google';

export default function GoogleCloud() {
  const [loadingMessage, setLoadingMessage] = useState("");
  const [dicomImages, setDicomImages] = useState([]);
  const [loadingDicomImages, setLoadingDicomImages] = useState("");
  const [uploadedUrl, setUploadedUrl] = useState("");

  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const bucketName = process.env.REACT_APP_BUCKET_NAME;
  const projectName = process.env.REACT_APP_PROJECT_NAME;
  const dicomStore = process.env.REACT_APP_DICOM_STORE;
  const dataset = process.env.REACT_APP_DATASET;
  const location = process.env.REACT_APP_LOCATION;
  const dicomFolder = process.env.REACT_APP_DICOM_FOLDER;
  const googleStorageBase = process.env.REACT_APP_GOOGLE_STORAGE_BASE;
  const googleHealthBase = process.env.REACT_APP_GOOGLE_HEALTH_BASE;
  const [token, setToken] = useState("");

  const login = useGoogleLogin({
    scope:'https://www.googleapis.com/auth/cloud-platform',
    onSuccess: tokenResponse => {
      setToken(tokenResponse.access_token);
      getStorageData(tokenResponse.access_token);
    }
  });



  const handleFileSelect = async (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    const result = evt.target.files[0];
    const filename = result.name.split(".")[0];

    setLoadingMessage("Uploading to Cloud Storage");
    let response = await fetch(
      `${googleStorageBase}/upload/storage/v1/b/${bucketName}/o?uploadType=media&name=${filename}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/dicom",
          Authorization: `Bearer ${token}`,
        },
        body: result,
      }
    );
    if (response.status === 200) {
      setLoadingMessage("Uploading to Dicom Store");
      setUploadedUrl(filename);

      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        gcsSource: {
          uri: `gs://${bucketName}/${filename}`,
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
// TODO: check if we need to await this fetch
      fetch(
        `${googleHealthBase}/v1/projects/${projectName}/locations/${location}/datasets/${dataset}/dicomStores/${dicomStore}:import`,
        requestOptions
      )
        .then((response) => {
          response.text();
          setLoadingMessage("");
          if (response.status === 200) {
            setSuccess(true);
          } else {
            if (response.status === 401) {
              setError("401 - Invalid credentials");
            } else {
              setError("Error");
            }
          }
        })
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));

      /* Export store to Bucket */
      exportDICOMStoreToBucket(token);
    } else {
      setLoadingMessage("");
      if (response.status === 401) {
        setError("401 - Invalid credentials");
      } else {
        setError("Error");
      }
    }
  };

  const exportDICOMStoreToBucket = async (token) => {
    /* Export store to Bucket */
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var raw = JSON.stringify({
      gcsDestination: {
        uriPrefix: `gs://${bucketName}/${dicomFolder}/`,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const exportBucket = await fetch(
      `${googleHealthBase}/v1beta1/projects/${projectName}/locations/${location}/datasets/${dataset}/dicomStores/${dicomStore}:export`,
      requestOptions
    );

    if (exportBucket.status !== 200) {
      if (exportBucket.status === 401) {
        setError("401 - Invalid credentials");
      } else {
        setError("Error");
      }
      setLoadingDicomImages("");
      return;
    }
  };

  const getStorageData = async (accessToken) => {
    var myHeaders = new Headers();
    setLoadingDicomImages("Reading DICOM images...");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    exportDICOMStoreToBucket(accessToken);
    /* Get file from Bucket */

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const response = await fetch(
      `${googleStorageBase}/storage/v1/b/${bucketName}/o?prefix=${dicomFolder}`,
      requestOptions
    );
    if (response.status === 200) {
      const dicomImages = [];
      const data = await response.json();

      data.items.forEach((element) => {
        if (element.contentType === "application/octet-stream") {
          dicomImages.push(element.name);
        }
      });
      setLoadingDicomImages("");
      setDicomImages(dicomImages);
    } else { 
    if (response.status === 401) {
      setError("401 - Invalid credentials");
    } else {
      setError("Error");
    }
  }
  
    setLoadingDicomImages("");
  };

  useEffect(() => {
    initCornerstone();
  }, []);

  return (
    <div className="h-full flex flex-col gap-4">
      <div className="my-2">
        {loadingMessage && <Loader primaryMessage={loadingMessage} />}
        {error && <ErrorComponent primaryMessage={error} />}
        {success && (
          <SuccessComponent
            primaryMessage="File uploaded successfully!"
            secondaryMessage="Refresh page to view dicom Image on the grid (Might take some minutes to load)"
          />
        )}
      </div>
      <div>
        <button className="shadow-lg rounded-lg px-2 py-4" onClick={() => login()}>
          {token ? 'Refresh' : 'Get'} access token
        </button>
      </div>
      {token && 
        <div>
          <h1 className="text-2xl font-semibold">Google Cloud </h1>
          <li>Store: {dicomStore}</li>
          <li>Project Name: {projectName}</li>
          <li>Data Set: {dataset}</li>
          <div className="my-4">
            <h1 className="mb-3 font-semibold">Upload file to {dicomStore}</h1>
            <li>
              Files with same DICOM metadata are replaced, not stored as duplicated
            </li>
            <br />
            {/* File Input */}
            <input
              className={`
                    block max-w-2xl text-gray-900 border border-gray-900 cursor-pointer
                    file:cursor-pointer file:bg-gray-900 file:text-white file:border-none file:focus:outline-none file:px-3 file:py-1 file:mr-2.5
                `}
              type="file"
              onChange={handleFileSelect}
              accept=".dicom"
            />

            {/* Image Viewport */}

            <div className="my-3">
              <h1 className="mb-3">Preview DICOM file uploaded on Store</h1>
              {uploadedUrl && (
                <DicomViewer
                  url={uploadedUrl}
                  googleStorageBase={googleStorageBase}
                  bucketName={bucketName}
                />
              )}
            </div>
          </div>
          <strong>Files from DICOM store</strong>
          {loadingDicomImages && <Loader primaryMessage={loadingDicomImages} />}
          <div className="flex flex-wrap">
            {dicomImages.length > 0 &&
              dicomImages.map((element, index) => (
                <div key={index}>
                  <DicomViewer
                    url={element}
                    googleStorageBase={googleStorageBase}
                    bucketName={bucketName}
                  />
                </div>
              ))}
          </div>
        </div>
      }
    </div>
  );
}
