import cornerstone from 'cornerstone-core';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import cornerstoneTools from 'cornerstone-tools';
import dicomParser from 'dicom-parser';
import cornerstoneMath from 'cornerstone-math';
import Hammer from 'hammerjs';

const metadataMap = {
  "x00100010": "patientName",
  "x00100020": "patientId",
  "x00100030": "patientBirthDate",
  "x00100040": "patientSex",
  "x00081030": "studyDescription",
  "x00181030": "protocolName",
  "x00080050": "accessionNumber",
  "x00200010": "studyId",
  "x00080020": "studyDate",
  "x00080030": "studyTime",
  "x0008103e": "seriesDescription",
  "x00200011": "seriesNumber",
  "x00080060": "modality",
  "x00180015": "bodyPart",
  "x00080021": "seriesDate",
  "x00080031": "seriesTime",
  "x00200013": "instanceNumber",
  "x00200012": "aquisitionNumber",
  "x00080022": "aquisitionDate",
  "x00080032": "aquisitionTime",
  "x00080023": "contentDate",
  "x00080033": "contentTime",
  "x00280010": "imageRows",
  "x00280011": "imageColumns",
  "x00280004": "photometricInterpretation",
  "x00080008": "imageType",
  "x00280100": "bitsAllocated",
  "x00280101": "bitsStored",
  "x00280102": "highBit",
  "x00280103": "pixelRepresentation",
  "x00281053": "rescaleSlope",
  "x00281052": "rescaleIntercept",
  "x00200032": "imagePositionPatient",
  "x00200037": "imageOrientationPatient",
  "x00280030": "pixelSpacing",
  "x00280002": "samplesPerPixel",
  "x00080070": "manufacturer",
  "x00081090": "model",
  "x00081010": "stationName",
  "x00020016": "AE Title",
  "x00080080": "institutionName",
  "x00020013": "implementationVersionName",
  "x0020000d": "studyUid",
  "x0020000e": "seriesUid",
  "x00080018": "instanceUid",
  "x00080016": "sopClassUid",
  "x00020010": "transferSyntaxUid",
  "x00200052": "frameOfReferenceUid",
}

const TOOLS = {
  WWWC: {
    name: 'Wwwc',
    toolName: 'WwwcTool',
    activationConfig: { mouseButtonMask: 1 }
  },
  PAN: {
    name: 'Pan',
    toolName: 'PanTool',
    activationConfig: { mouseButtonMask: 2 }
  },
  ZOOM_WHEEL: {
    name: 'ZoomMouseWheel',
    toolName: 'ZoomMouseWheelTool',
    activationConfig: {}
  },
}

export function init() {
  // Cornerstone Tools
  cornerstoneTools.external.cornerstone = cornerstone;
  cornerstoneTools.external.Hammer = Hammer;
  cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
  cornerstoneTools.init({
    mouseEnabled: true,
    touchEnabled: true,
    globalToolSyncEnabled: false,
    showSVGCursors: true,
  });

  // WADO Image Loader
  const config = {
    maxWebWorkers: navigator.hardwareConcurrency || 1,
    startWebWorkersOnDemand: false,
    taskConfiguration: {
      decodeTask: {
        initializeCodecsOnStartup: false,
      },
    },
  }

  cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
  cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
  cornerstoneWADOImageLoader.webWorkerManager.initialize(config);
}

export function enableElement(element) {
  cornerstone.enable(element);
}

export function addToolForElement(element, toolKey, config) {
  const { name, toolName, activationConfig } = TOOLS[toolKey];
  const cornerstoneTool = cornerstoneTools[toolName];

  const toolAlreadyAddedToElement = !!cornerstoneTools.getToolForElement(element, name);

  if (!toolAlreadyAddedToElement) {
    cornerstoneTools.addToolForElement(element, cornerstoneTool);
  };

  cornerstoneTools.setToolActive(name, config ?? activationConfig);
}

function getImageMetadata(dataSet) {
  const fn = (key) => dataSet.string(key ?? '');
  const formattedMetadataEntries = Object.entries(metadataMap).map(([key, value]) => [value, fn(key)]);
  const formattedMetadata = Object.fromEntries(formattedMetadataEntries);

  return formattedMetadata
}

export function readMetadata(file) {

  const reader = new FileReader();
  reader.onload = () => {
    const arrayBuffer = reader.result;
    const byteArray = new Uint8Array(arrayBuffer);

    try {
      const dataSet = dicomParser.parseDicom(byteArray);
      const imageMetadata = getImageMetadata(dataSet);
      console.log(imageMetadata);
    }
    catch (err) {
      console.log(err);
    }
  };

  reader.readAsArrayBuffer(file);
}

export function loadAndViewImage(imageId, element) {
  if (element === null) return;

  cornerstone.enable(element);
  cornerstone.loadImage(imageId).then((image) => {
    const viewport = cornerstone.getDefaultViewportForImage(element, image);
    cornerstone.displayImage(element, image, viewport);
    
  }, (err) => {
    console.log(err.error)
  });
}

export function readMetadataFromUrl(imageId){
  cornerstone.loadImage(imageId).then((image) => {  
    const byteArray = image.data.byteArray;
    try {
      const dataSet = dicomParser.parseDicom(byteArray);
      const imageMetadata = getImageMetadata(dataSet);
      console.log(imageMetadata);
    }
    catch (err) {
      console.log(err);
    }

  }, (err) => {
    console.log(err.error)
  });
}