import { useState, useEffect, useRef } from 'react';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import {
  init as initCornerstone,
  loadAndViewImage,
  readMetadata,
  readMetadataFromUrl,
  enableElement,
  addToolForElement
} from '../../utils/cornerstone';

export default function Visualizer() {
  const dicomImageRef = useRef(null)
  const [toolsLoaded, setToolsLoaded] = useState(false);
  const [searchValue, setSearchValue] = useState("")

  function handleFileSelect(evt) {
    evt.stopPropagation();
    evt.preventDefault();

    const [file] = evt.target.files;
    const imageId = cornerstoneWADOImageLoader.wadouri.fileManager.add(file);
    loadAndViewImage(imageId, dicomImageRef.current);
    readMetadata(file);
  }

  function handleFileSearch(evt) {
    evt.preventDefault();
    let url = searchValue

    // prefix the url with wadouri: so cornerstone can find the image loader
    url = "wadouri:" + url;

    loadAndViewImage(url, dicomImageRef.current);
    readMetadataFromUrl(url);
  }

  function handleSearchValueChange(evt) {
    setSearchValue(evt.target.value)
  }

  useEffect(() => {
    if (toolsLoaded === true) return;

    const element = dicomImageRef.current;
    enableElement(element);
    addToolForElement(element, 'WWWC');
    addToolForElement(element, 'PAN');
    addToolForElement(element, 'ZOOM_WHEEL');

    setToolsLoaded(true);
  }, [toolsLoaded]);

  useEffect(() => {
    initCornerstone()
  }, []);

  return (
    <div className="h-full flex flex-col gap-4">
      {/* File Input */}
      <input
        className={`
        block max-w-2xl text-gray-900 border border-gray-900 cursor-pointer
        file:cursor-pointer file:bg-gray-900 file:text-white file:border-none file:focus:outline-none file:px-3 file:py-1 file:mr-2.5
      `}
        type="file"
        onChange={handleFileSelect}
        accept=".dicom"
      />
      {/* File Search */}
      <form onSubmit={handleFileSearch}>
        <div className="flex">
          <div className="relative w-full max-w-2xl">
            <input
              value={searchValue}
              onChange={handleSearchValueChange}
              type="search"
              className="block px-2.5 py-2 w-full text-sm border outline-none border-gray-900 placeholder-gray-400"
              placeholder="Search for an image URL"
              required
            />
            <button type="submit" className="absolute top-0 right-0 px-3 py-1.5 font-medium text-white border border-gray-900 bg-gray-900">
              <span>Search</span>
            </button>
          </div>
        </div>
      </form>
      {/* Image Viewport */}
      <div ref={dicomImageRef} id="dicomImage" className="w-full max-w-2xl aspect-square border border-gray-900" onContextMenu={(e) => e.preventDefault()} />
    </div>
  )
}