import React from "react";

export const ErrorComponent = ({ primaryMessage, secondaryMessage }) => {
  return (
    <div
      className="absolute top-[80px] w-full ml-[-16px] text-center p-2 mb-4 text-sm text-red-700 bg-red-100 dark:bg-red-200 dark:text-red-800"
      role="alert"
    >
      <span className="font-medium">{primaryMessage}</span> {secondaryMessage}
    </div>
  );
};
