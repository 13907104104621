import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import GoogleCloud from "./pages/GoogleCloud/GoogleCloud";
import Visualizer from "./pages/Visualizer/Visualizer"
import { GoogleOAuthProvider } from '@react-oauth/google';


function DicomConverter() {
  return (
    <div>Converter</div>
  )
}

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

function App() {
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <div className="flex flex-col flex-1 min-h-screen overflow-hidden">
        <BrowserRouter>
          <Navbar />
          <div className="h-full m-4">
            <Routes>
              <Route path="/" element={<Visualizer />} />
              <Route path="converter/*" element={<DicomConverter />} />
              <Route path="google-cloud/*" element={<GoogleCloud />} />
            </Routes>
          </div>
        </BrowserRouter>
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;
