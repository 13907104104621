import React from "react";

export const SuccessComponent = ({ primaryMessage, secondaryMessage }) => {
  return (
    <div
      className="absolute top-[80px] w-full ml-[-16px] p-2 my-2 text-sm text-green-700 bg-green-100 dark:bg-green-200 dark:text-green-800"
      role="alert"
    >
      <span className="font-medium">{primaryMessage}!</span> {secondaryMessage}
    </div>
  );
};
