import React, { useEffect, useRef, useState } from "react";
import {
  init as initCornerstone,
  loadAndViewImage,
  readMetadata,
  enableElement,
  addToolForElement,
  readMetadataFromUrl,
} from "../../utils/cornerstone";

export const DicomViewer = ({ url, bucketName, googleStorageBase }) => {
  const dicomImageRef = useRef(null);
  const [toolsLoaded, setToolsLoaded] = useState(false);

  useEffect(() => {
    if (toolsLoaded === true) return;

    const element = dicomImageRef.current;
    enableElement(element);
    addToolForElement(element, "WWWC");
    addToolForElement(element, "PAN");
    addToolForElement(element, "ZOOM_WHEEL");
    setToolsLoaded(true);
  }, [toolsLoaded]);

  useEffect(() => {
    initCornerstone();
    if (url !== "") {
      const gsUrl = `${googleStorageBase}/${bucketName}/${url}`;

      // prefix the url with wadouri: so cornerstone can find the image loader
      const dicomUrl = "wadouri:" + gsUrl;
      readMetadataFromUrl(dicomUrl);
      loadAndViewImage(dicomUrl, dicomImageRef.current);
    }
  }, []);
  return (
    <div className="p-2 mb-4 text-sm rounded-lg" role="alert">
      <div
        ref={dicomImageRef}
        id="dicomImage"
        className="w-full max-w-2xl aspect-square border border-gray-900"
        onContextMenu={(e) => e.preventDefault()}
      />
    </div>
  );
};
