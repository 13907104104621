import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <nav className="border-gray-200 px-2 sm:px-4 py-2.5 bg-gray-900">
      <div className="container flex flex-wrap items-center justify-center mx-auto">
        <div className="w-full block" id="navbar-default">
          <ul className="flex justify-center p-4 rounded-lg flex-row space-x-8 mt-0 text-sm font-medium border-0 bg-gray-900 border-gray-700">
            <Link to="/" className="block py-2 pl-3 pr-4 rounded border-0 p-0 text-gray-400 hover:bg-gray-700 hover:text-white hover:bg-transparent">
              <li>
                Visualizer
              </li>
            </Link>
            {/* <Link to="converter" className="block py-2 pl-3 pr-4 rounded border-0 p-0 text-gray-400 hover:bg-gray-700 hover:text-white hover:bg-transparent">
              <li>
                Converter
              </li>
            </Link> */}
            <Link to="google-cloud" className="block py-2 pl-3 pr-4 rounded border-0 p-0 text-gray-400 hover:bg-gray-700 hover:text-white hover:bg-transparent">
              <li>
                Google Cloud
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </nav>
  )
}
